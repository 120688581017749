/// <reference types="firebase" />
var form = document.getElementById('contactForm');
var config = {
    apiKey: 'AIzaSyBK_AFnNePR9AHgk97VTibwcLrLtbHxLU4',
    authDomain: 'marlinmaster-prod.firebaseapp.com',
    databaseURL: 'https://marlinmaster-prod.firebaseio.com',
    projectId: 'marlinmaster-prod',
    storageBucket: 'marlinmaster-prod.appspot.com',
    messagingSenderId: '689981996974'
};
firebase.initializeApp(config);
var messagesRef = firebase.database().ref('messages');
var fields = ['name', 'email', 'message'];
if (form) {
    form.addEventListener('submit', function (e) {
        e.preventDefault();
        var message = {};
        fields.forEach(function (field) {
            var input = document.getElementById(field);
            if (input) {
                // tslint:disable-next-line:whitespace
                message[field] = input.value;
            }
        });
        var messageRef = messagesRef.push();
        messageRef.set(message).then(function () {
            var alert = document.getElementById('messageSentAlert');
            if (!alert) {
                return;
            }
            alert.style.display = 'block';
            // tslint:disable-next-line:whitespace
            form.reset();
            setTimeout(function () {
                alert.style.display = 'none';
                // tslint:disable-next-line:align
            }, 3000);
        });
        console.log(message);
    });
}
console.log(form);
if (module.hot) {
    module.hot.accept();
}
